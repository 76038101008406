<template>
  <div class="container">
    <div class="title-label">
      <div class="title-label-name">{{ $t('manager-profile.ДоброПожаловать') }} {{ user.first_name }},</div>
      <div class="title-label-optional">{{ $t('manager-profile.ПосмотретьАналитикуИСтатистику') }}</div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="manager-card" style="width: 100%">
        <div class="manager-card-label">{{ $t('manager-profile.ВсегоПрошлиТестПо') }}{{ user.region.name }}</div>
        <div class="manager-card-count">{{ partOne.total+partTwo.finished_second_step+partTwo.finished_proforientation }}</div>
      </div>
      <div class="manager-card">
        <div class="manager-card-label">{{ $t('manager-profile.ПрошедшихТестПоДиагностикеСпособностей1Шага') }}</div>
        <div class="manager-card-count">{{ partOne.diagnostic }}</div>
      </div>
      <div class="manager-card">
        <div class="manager-card-label">{{ $t('manager-profile.ПрошедшихТестПодиагностикеСпособностей2Шага') }}</div>
        <div class="manager-card-count">{{ partTwo.finished_second_step }}</div>
      </div>
      <div class="manager-card">
        <div class="manager-card-label">{{ $t('manager-profile.ПрошедшихТестПоПрофориентации') }}</div>
        <div class="manager-card-count">{{ partTwo.finished_proforientation }}</div>
      </div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="manager-card" style="width: 100%">
        <div class="manager-card-label">{{ $t('manager-profile.ВсегоЗаписавшихсяНаТестВЦентрQabilet') }}</div>
        <div class="manager-card-count">{{ partTwo.total }}</div>
      </div>
      <div class="manager-card-medium">
        <div class="image-icon-wrapper">
          <img style="margin: auto" width="22px" height="22px" src="/images/edit.svg" alt="">
        </div>
        <div class="manager-card-medium-label">{{
            $t('manager-profile.ЗаписавшихсяНаТестПоДиагностикеСпособностей1Шага')
          }}
        </div>
        <div class="manager-card-medium-count">{{ partTwo.diagnostic }}</div>
      </div>
      <div class="manager-card-medium">
        <div class="image-icon-wrapper">
          <img style="margin: auto" width="22px" height="22px" src="/images/peoples-two.svg" alt="">
        </div>
        <div class="manager-card-medium-label">{{
            $t('manager-profile.ЗаписавшихсяНаТестПоДиагностикеСпособностей2Шага')
          }}
        </div>
        <div class="manager-card-medium-count">{{ partTwo.second_step }}</div>

      </div>
      <div class="manager-card-medium">
        <div class="image-icon-wrapper">
          <img style="margin: auto" width="22px" height="22px" src="/images/peoples-two.svg" alt="">
        </div>
        <div class="manager-card-medium-label">{{ $t('manager-profile.ЗаписавшихсяНаТестПоПрофориентации') }}</div>
        <div class="manager-card-medium-count">{{ partTwo.proforientation }}</div>
      </div>
      <div class="manager-card" style="width: 100%">
        <div class="manager-card-label">{{ $t('manager-profile.ВсегоПрошлитествЦентреQabilet') }}</div>
        <div class="manager-card-count">{{ partTwo.finished_total }}</div>
      </div>
      <div class="manager-card">
        <div class="manager-card-count-top">{{ partTwo.finished_diagnostic }}</div>
        <div class="manager-card-line"></div>
        <div class="manager-card-label-bottom">
          {{ $t('manager-profile.ПрошедшихТестВЦентреQabiletПоДиагностикеСпособностей1Шага') }}
        </div>
      </div>
      <div class="manager-card">
        <div class="manager-card-count-top">{{ partTwo.finished_second_step }}</div>
        <div class="manager-card-line"></div>
        <div class="manager-card-label-bottom">
          {{ $t('manager-profile.ПрошедшихТестВЦентреQabiletПоДиагностикеСпособностей2Шага') }}
        </div>

      </div>
      <div class="manager-card">
        <div class="manager-card-count-top">{{ partTwo.finished_proforientation }}</div>
        <div class="manager-card-line"></div>
        <div class="manager-card-label-bottom">{{ $t('manager-profile.ПрошедшихтествЦентреQabiletпопрофориентации') }}</div>
      </div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="manager-card-half">
        <div class="manager-card-medium-label">{{
            $t('manager-profile.ЗаписавшихсяНаКонсультациюКПсихологуЦентра')
          }}
        </div>
        <div class="manager-card-count">{{ partThree.center_total }}</div>
      </div>
      <div class="manager-card-half">
        <div class="manager-card-medium-label">{{ $t('manager-profile.ПолучившихКонсультациюПсихологаЦентра') }}</div>
        <div class="manager-card-count">{{ partThree.center_finished }}</div>
      </div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="manager-card-half">
        <div class="manager-card-medium-label">{{ $t('manager-profile.ЗаписавшихсяНаконсультациюКпсихологуШколы') }}
        </div>
        <div class="manager-card-count">{{ partThree.school_total }}</div>
      </div>
      <div class="manager-card-half">
        <div class="manager-card-medium-label">{{ $t('manager-profile.ПолучившихКонсультациюПсихологаШколы') }}</div>
        <div class="manager-card-count">{{ partThree.school_finished }}</div>
      </div>
    </div>
    <div class="manager-card" style="width: 100%;">
      <div class="manager-card-label">
        {{ $t('manager-profile.ПолучившихКонсультациюПсихологаДетскогоСадаДошкольников') }}
      </div>
      <div class="manager-card-count">{{ partThree.kindergarten_finished }}</div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="manager-card-half">
        <div class="manager-card-medium-label">
          {{ $t('manager-profile.КоличествоЗарегистрированныхУчастниковТестированияДанногоРегиона') }}
        </div>
        <div class="manager-card-count">{{ partFour.registered_by_region }}</div>
      </div>
      <div class="manager-card-half">
        <div class="manager-card-medium-label">
          {{ $t('manager-profile.КоличествоЗарегистрированныхРодителейДанногоРегиона') }}
        </div>
        <div class="manager-card-count">{{ partFour.parents }}</div>
      </div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="count-logo-card-left">
        <div class="count-logo-card-globe-left">
          <img style="margin: auto" width="22px" height="22px" src="/images/globe.svg" alt="">
        </div>
        <div class="logo-card-label">{{ $t('manager-profile.КоличествоЗарегистрированныхТренеровЦентра') }}</div>
        <div class="logo-card-count"><span v-if="partFour.psychologists">{{ partFour.psychologists.coach }}</span></div>
      </div>
      <div class="count-logo-card-right">
        <div class="count-logo-card-globe-right">
          <img style="margin: auto" width="22px" height="22px" src="/images/globe.svg" alt="">
        </div>
        <div class="logo-card-label">{{ $t('manager-profile.КоличествоЗарегистрированныхПсихологовШкол') }}</div>
        <div class="logo-card-count"><span>{{ partFour.psychologists.school }}</span></div>

      </div>
      <div class="qabilet-round">
        <img style="margin: auto" width="163px" height="57px" src="/images/Qabiletogo.svg">
      </div>
      <div class="count-logo-card-left">
        <div class="count-logo-card-globe-left">
          <img style="margin: auto" width="22px" height="22px" src="/images/globe.svg" alt="">
        </div>
        <div class="logo-card-label">{{ $t('manager-profile.КоличествоЗарегистрированныхПсихологовЦентра') }}</div>
        <div class="logo-card-count">{{ partFour.psychologists.center }}</div>
      </div>
      <div class="count-logo-card-right">
        <div class="count-logo-card-globe-right">
          <img style="margin: auto" width="22px" height="22px" src="/images/globe.svg" alt="">
        </div>
        <div class="logo-card-label">{{ $t('manager-profile.КоличествоЗарегистрированныхПсихологовДетскихСадов') }}
        </div>
        <div class="logo-card-count">{{ partFour.psychologists.kindergarten }}</div>
      </div>
    </div>
    <div class="manager-cards-wrapper">
      <div class="manager-card-half">
        <div class="manager-card-medium-label">
          {{ $t('manager-profile.ПсихологовШкол,ПрошедшихОбучениеНаказахскомЯзыке') }}
        </div>
        <div class="manager-card-count">{{ partFour.psychologists.trained_school_kz }}</div>
      </div>
      <div class="manager-card-half">
        <div class="manager-card-medium-label">
          {{ $t('manager-profile.ПсихологовШкол,ПрошедшихОбучениеНаРусскомЯзыке') }}
        </div>
        <div class="manager-card-count">{{ partFour.psychologists.trained_school_ru }}</div>
      </div>
      <div class="manager-card-half">
        <div class="manager-card-medium-label">
          {{ $t('manager-profile.ПсихологовДетскихСадов,ПрошедшихОбучениеНаКазахскомяЯыке') }}
        </div>
        <div class="manager-card-count">{{ partFour.psychologists.trained_kindergarten_kz }}</div>
      </div>
      <div class="manager-card-half">
        <div class="manager-card-medium-label">
          {{ $t('manager-profile.ПсихологовДетскихСадовПрошедшихОбучениеНаРусскомЯзыке') }}
        </div>
        <div class="manager-card-count">{{ partFour.psychologists.trained_kindergarten_ru }}</div>
      </div>
    </div>
    <section class="table-section">
      <span class="table-title">{{ $t('manager-profile.ТаблицаДошкольников') }}</span>
      <div class="table-wrap" >
        <div class="table-filter">
          <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.city_id" class="table-filter-4" :placeholder="$t('profile.consultations.district')" @change="getLocalitiesPreschoolers">
            <el-option
                v-for="item in citiesPreschoolers"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.locality_id" class="table-filter-4"
                     :placeholder="$t('profile.consultations.city')" @change="getKindergartenPreschoolers">
            <el-option
                v-for="item in localitiesPreschoolers "
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="preschoolersLoad"  @change=" preschoolersFilter" v-model="preschoolersFilt.kindergarten" class="table-filter-4" :placeholder="$t('manager-profile.детскийCад')">
            <el-option
                v-for="item in kindergatenPreschoolers"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="preschoolersLoad" v-model="preschoolersFilt.age" class="table-filter-4" :placeholder="$t('manager-profile.возраст')"  @change=" preschoolersFilter">
            <el-option
                v-for="age in ages"
                :key="age.name"
                :label="age.name"
                :value="age.val">
            </el-option>
          </el-select>
          <el-date-picker
              :disabled="preschoolersLoad"
              @change="preschoolersFilter"
              v-model="preschoolersFilt.yearFrom"
              class="table-filter-8"
              type="date"
              :placeholder="$t('manager-profile.датаОт')"
              format="yyyy.MM.dd"
              value-format="yyyy.MM.dd"
          />
          <el-date-picker
              :disabled="preschoolersLoad"
              @change="preschoolersFilter"
              v-model="preschoolersFilt.yearTo"
              class="table-filter-8"
              type="date"
              :placeholder="$t('manager-profile.датаДо')"
              format="yyyy.MM.dd"
              value-format="yyyy.MM.dd"
          />
          <button :disabled="preschoolersLoad" @click="clearTable('preschoolers')" class="table-filter-4 btn-filter-clear">{{$t('references.reset-btn')}}</button>
        </div>
        <div v-show="preschoolersLoad==true" class="loader"></div>
        <div v-show="preschoolersLoad==false" class="customScroll">
          <table  style="text-align: center" class="table stat-age__table">
            <thead>

            <th>
              №
            </th>
            <th>
              {{$t('profile.iin-label')}}
            </th>
            <th>
              {{$t('profile.fio')}}
            </th>
            <th>
              {{$t('profile.birth-date-label')}}
            </th>
            <th>
              {{$t('references.gender')}}
            </th>
            <th>
              {{$t('profile.consultations.region')}}
            </th>
            <th>
              {{$t('profile.consultations.district')}}
            </th>
            <th>
              {{$t('profile.consultations.city')}}
            </th>
            <th>
              {{$t('manager-profile.детскийCад')}}
            </th>
            <th>
              {{$t('manager-profile.фиоРодителя')}}
            </th>
            <th>
              {{$t('manager-profile.телефонРодителя')}}
            </th>
            <th>
              {{$t('manager-profile.подтверждениeЛичности')}}
            </th>
            <th>
              {{$t('manager-profile.комментарий')}}
            </th>
            <th> {{$t('main-page.test-results')}}</th>
            </thead>
            <tbody>
            <tr v-for="(item,index) in preschoolers" :key="index">
              <td>
                {{index+1}}
              </td>
              <td>
                {{item.iin}}
              </td>
              <td>
                {{item.fullname}}
              </td>
              <td>
                {{item.birth_date.split('-').join('.')}}
              </td>
              <td>{{item.gender==1?$t('references.gender-male'):$t('references.gender-female')}}</td>
              <td><span v-if="item.region">{{item.region.name}}</span></td>
              <td><span v-if="item.city">{{item.city.name}}</span></td>
              <td><span v-if="item.locality">{{item.locality.name}}</span></td>
              <td><span v-if="item.kindergarten">{{item.kindergarten.name}}</span></td>
              <td><span v-if="item.parent">{{item.parent.fullname}}</span></td>
              <td><span v-if="item.parent">{{item.parent.phone | Phone}}</span></td>
              <td><span >{{item.confirmation}}</span></td>
              <td><span >{{item.comment}}</span></td>
              <td>
                <a :href="API_ROOT+'/storage/'+item.diagnostic" download
                   style="display: block" class="btn btn-blue">
                  {{ $t('test-results.download-file') }}
                </a></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-lk-wrapper">
          <el-pagination
              v-show="preschoolersLoad==false"
              :current-page='preschoolersMeta.current_page'
              @current-change="paginatePreschoolers"
              layout="prev, pager, next"
              :page-count="preschoolersMeta.last_page" >
          </el-pagination>

        </div>
      </div>
    </section>
    <section class="table-section">
      <span class="table-title">{{ $t('manager-profile.ТаблицаДиагностикИСпособностей') }}</span>
      <div class="table-wrap">
        <div class="table-filter">
          <el-select  :disabled="usersLoad" v-model="usersFilt.city_id" @change="getLocalitiesUsers" class="table-filter-4" :placeholder="$t('profile.consultations.district')">
            <el-option
                v-for="item in citiesUsers "
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select  :disabled="usersLoad" v-model="usersFilt.locality_id" @change="getSchoolUsers"  class="table-filter-4"
                     :placeholder="$t('profile.consultations.city')">
            <el-option
                v-for="item in localitiesUsers "
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select
              :disabled="usersLoad"
              @change="usersFilter()"
              v-model="usersFilt.school" class="table-filter-4" :placeholder="$t('references.school-name-placeholder')">
            <el-option
                v-for="item in schoolUsers "
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select
              :disabled="usersLoad"
              @change="usersFilter()"
              v-model="usersFilt.age" class="table-filter-4" :placeholder="$t('manager-profile.возраст')">
            <el-option
                v-for="age in ages"
                :key="age.name"
                :label="age.name"
                :value="age.val">
            </el-option>
          </el-select>
          <el-date-picker
              :disabled="usersLoad"
              @change="usersFilter()"
              v-model="usersFilt.yearFrom"
              class="table-filter-8"
              type="date"
              :placeholder="$t('manager-profile.датаОт')"
              format="yyyy.MM.dd"
              value-format="yyyy.MM.dd"
          />
          <el-date-picker
              :disabled="usersLoad"
              @change="usersFilter()"
              v-model="usersFilt.yearTo"
              class="table-filter-8"
              type="date"
              :placeholder="$t('manager-profile.датаДо')"
              format="yyyy.MM.dd"
              value-format="yyyy.MM.dd"
          />
          <button @click="clearTable('users')" class="table-filter-4 btn-filter-clear">{{$t('references.reset-btn')}}</button>
        </div>
        <div v-show="usersLoad==true" class="loader"></div>
        <div v-show="usersLoad==false" class="customScroll">
          <table  style="text-align: center" class="table stat-age__table">
            <thead>
            <tr>
              <th rowspan="2" colspan="1">
                №
              </th>
              <th rowspan="2" colspan="1">
                {{$t('profile.iin-label')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('profile.fio')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('profile.birth-date-label')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('references.gender')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('signin-form.phone-label')}}
              </th>
             <th rowspan="1" colspan="3">{{$t('special-schools.address')}}</th>
              <th rowspan="1" colspan="5">{{$t('profile.education')}}</th>
              <th rowspan="1" colspan="2">{{$t('profile.representative')}}</th>
              <th rowspan="1" colspan="5">{{$t('profile.notifications.filter-testing-title')}}</th>
            </tr>
            <tr>
              <th>
               {{$t('profile.consultations.region')}}
              </th>
              <th>
                {{$t('profile.consultations.district')}}
              </th>
              <th>
                {{$t('profile.consultations.city')}}
              </th>
              <th>
                {{$t('profile.consultations.region')}}
              </th>
              <th>
                {{$t('profile.consultations.district')}}
              </th>
              <th>
                {{$t('profile.consultations.city')}}
              </th>
              <th>
                {{$t('profile.consultations.school')}}
              </th>
              <th>
                {{$t('profile.consultations.class')}}
              </th>
              <th>
                {{$t('profile.consultations.full-name')}}
              </th>
              <th>
                {{$t('signin-form.phone-label')}}
              </th>
              <th>
                {{$t('profile.results.title')}}
              </th>
              <th>
               {{$t('manager-profile.максБаллов')}}
              </th>
              <th>
                 {{$t('manager-profile.датаПрохождения')}}
              </th>
              <th>
              {{$t('manager-profile.затраченноеВремя')}}
              </th>
              <th>
                {{$t('manager-profile.Допущенк2шагу')}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in users" :key="index">
              <td>
                {{index+1}}
              </td>
              <td>
                {{item.profile.iin}}
              </td>
              <td>
                {{item.profile.fullname}}
              </td>
              <td>
                {{item.profile.birth_date.split('-').join('.')}}
              </td>
              <td>{{item.profile.gender==1?$t('references.gender-male'):$t('references.gender-female')}}</td>
              <td>{{item.profile.phone | Phone}}</td>
              <td><span v-if="item.address.region">{{item.address.region}}</span></td>
              <td><span v-if="item.address.city">{{item.address.city}}</span></td>
              <td><span v-if="item.address.locality">{{item.address.locality}}</span></td>
              <td><span v-if="item.education.region">{{item.education.region}}</span></td>
              <td><span v-if="item.education.city">{{item.education.city}}</span></td>
              <td><span v-if="item.education.locality">{{item.education.locality}}</span></td>
              <td><span v-if="item.education.school">{{item.education.school.name}}</span></td>
              <td>{{item.education.class}}</td>
              <td>{{item.parent.fullname}}</td>
              <td>{{item.parent.phone | Phone}}</td>
              <td>{{item.quiz.result}}</td>
              <td>{{item.quiz.max_result}}</td>
              <td>{{item.quiz.started.substring(0,10).split('-').join('.')}}</td>
              <td>{{item.quiz.duration | Time}}</td>
              <td>{{item.quiz.second_step_avalible==1?$t('manager-profile.Да'):$t('manager-profile.Нет')}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-lk-wrapper">
          <el-pagination
              v-show="usersLoad==false"
              :current-page='usersMeta.current_page'
              @current-change="paginateUsers"
              layout="prev, pager, next"
              :page-count="usersMeta.last_page" >
          </el-pagination>
        </div>
      </div>
    </section>
    <section class="table-section">
      <span class="table-title">{{$t('manager-profile.ТаблицаПрошедшегоТестирование2Шага')}}</span>
      <div class="table-wrap" style="margin-bottom: 3rem">
        <div class="table-filter">
          <el-select :disabled="secondStepLoad" v-model="secondStepFilt.city_id" @change="getLocalitiesSecondStep" class="table-filter-4" :placeholder="$t('profile.consultations.district')">
            <el-option
                v-for="item in citiesSecondStepFilt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="secondStepLoad" v-model="secondStepFilt.locality_id" class="table-filter-4" @change="getSchoolSecondStep"
                     :placeholder="$t('profile.consultations.city')">
            <el-option
                v-for="item in localitiesSecondStepFilt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="secondStepLoad" v-model="secondStepFilt.school" @change="secStepFilter" class="table-filter-4" :placeholder="$t('references.school-name-placeholder')" >
            <el-option
                v-for="item in schoolSecondStepFilt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select :disabled="secondStepLoad" v-model="secondStepFilt.age" @change="secStepFilter" class="table-filter-4" :placeholder="$t('manager-profile.возраст')">
            <el-option
                v-for="age in ages"
                :key="age.name"
                :label="age.name"
                :value="age.val">
            </el-option>
          </el-select>
          <el-date-picker
              :disabled="secondStepLoad"
              @change="secStepFilter"
              v-model="secondStepFilt.yearFrom"
              class="table-filter-8"
              type="date"
              :placeholder="$t('manager-profile.датаОт')"
              format="yyyy.MM.dd"
              value-format="yyyy.MM.dd"
          />
          <el-date-picker
              :disabled="secondStepLoad"
              @change="secStepFilter"
              v-model="secondStepFilt.yearTo"
              class="table-filter-8"
              type="date"
              :placeholder="$t('manager-profile.датаДо')"
              format="yyyy.MM.dd"
              value-format="yyyy.MM.dd"
          />
          <button :disabled="secondStepLoad" @click="clearTable('second-step')" class="table-filter-4 btn-filter-clear">{{$t('references.reset-btn')}}</button>
        </div>
        <div  v-show="secondStepLoad==true" class="loader"></div>
        <div v-show="secondStepLoad==false" class="customScroll">
          <table  style="text-align: center" class="table stat-age__table">
            <thead>
            <tr>
              <th rowspan="2" colspan="1">
                №
              </th>
              <th rowspan="2" colspan="1">
                {{$t('profile.iin-label')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('profile.fio')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('profile.birth-date-label')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('references.gender')}}
              </th>
              <th rowspan="2" colspan="1">
                {{$t('signin-form.phone-label')}}
              </th>
              <th rowspan="1" colspan="3">{{$t('special-schools.address')}}</th>
              <th rowspan="1" colspan="5">{{$t('profile.education')}}</th>
              <th rowspan="1" colspan="2">{{$t('profile.representative')}}</th>
              <th rowspan="1" colspan="2">{{$t('profile.notifications.filter-testing-title')}}</th>
            </tr>
            <tr>
              <th>
                {{$t('profile.consultations.region')}}
              </th>
              <th>
                {{$t('profile.consultations.district')}}
              </th>
              <th>
                {{$t('profile.consultations.city')}}
              </th>
              <th>
                {{$t('profile.consultations.region')}}
              </th>
              <th>
                {{$t('profile.consultations.district')}}
              </th>
              <th>
                {{$t('profile.consultations.city')}}
              </th>
              <th>
                {{$t('profile.consultations.school')}}
              </th>
              <th>
                {{$t('profile.consultations.class')}}
              </th>
              <th>
                {{$t('profile.fio')}}
              </th>
              <th>
                {{$t('signin-form.phone-label')}}
              </th>
              <th>
                {{$t('manager-profile.ДатаЗаполненияРезультата')}}
              </th>
              <th>
                {{$t('manager-profile.Автор')}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in secondStep" :key="index">
              <td>
                {{index+1}}
              </td>
              <td>
                {{item.user.iin}}
              </td>
              <td>
                {{item.user.fullname}}
              </td>
              <td>
               <span v-if="item.user.birth_date"> {{item.user.birth_date.split('-').join('.')}}</span>
              </td>
              <td>{{item.user.gender==1?$t('references.gender-male'):$t('references.gender-female')}}</td>
              <td>{{item.user.phone | Phone}}</td>
              <td><span v-if="item.address.region">{{item.address.region}}</span></td>
              <td><span v-if="item.address.city">{{item.address.city}}</span></td>
              <td><span v-if="item.address.locality">{{item.address.locality}}</span></td>
              <td><span v-if="item.education.region">{{item.education.region}}</span></td>
              <td><span v-if="item.education.city">{{item.education.city}}</span></td>
              <td><span v-if="item.education.locality">{{item.education.locality}}</span></td>
              <td><span v-if="item.education.school">{{item.education.school}}</span></td>
              <td>{{item.education.class}}</td>
              <td>{{item.parent.fullname}}</td>
              <td>{{item.parent.phone | Phone}}</td>
              <td>{{item.quiz.result_filled_at.substr(0,10).split('-').join('.')}}</td>
              <td>{{item.quiz.author}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-lk-wrapper">
          <el-pagination
              v-show="secondStepLoad==false"
              :current-page='secondStepMeta.current_page'
              @current-change="paginateSecondStep"
              layout="prev, pager, next"
              :page-count="secondStepMeta.last_page" >
          </el-pagination>
        </div>
      </div>
    </section>
  </div>
</template>
<script>


export default {
  data() {
    return {
      preschoolersLoad:true,
      usersLoad:true,
      secondStepLoad:true,
      regions:[],
      API_ROOT:window.API_ROOT,
      preschoolersFilt: {
        age: '',
        region_id: '',
        city_id: '',
        locality_id: '',
        kindergarten: '',
        yearFrom: '',
        yearTo: '',
      },
      usersFilt: {
        age: '',
        region_id: '',
        city_id: '',
        locality_id: '',
        school: '',
        yearFrom: '',
        yearTo: '',
      },
      secondStepFilt: {
        age: '',
        region_id: '',
        city_id: '',
        locality_id: '',
        school: '',
        yearFrom: '',
        yearTo: '',
      },
      user: {
        first_name: '',
        region: {
          name: ''
        }
      },
      ages: [
        {val:  'filter[age][from]=5&filter[age][to]=6', name: '5-6'},
        {val:  'filter[age][from]=7&filter[age][to]=9', name: '7-9'},
        {val:  'filter[age][from]=10&filter[age][to]=11', name: '10-11'},
        {val:  'filter[age][from]=12&filter[age][to]=14', name: '12-14'},
      ],
      preschoolers: {},
      secondStep:{},
      secondStepMeta:{
        per_page:0,
        current_page: 0,
        from: 0,
        last_page: 0,
        total: 0
      },
      preschoolersMeta:{
        per_page:0,
        current_page: 0,
        from: 0,
        last_page: 0,
        total: 0
      },
      usersMeta:{
        per_page:0,
        current_page: 0,
        from: 0,
        last_page: 0,
        total: 0
      },
      partOne: '',
      partTwo: '',
      partThree: '',
      partFour: '',
      users:[
        {
          "profile":{
            "iin":"0",
            "fullname":"",
            "birth_date":"2011-03-09",
            "gender":0,
            "phone":"0"
          },
          "address":{
            "region":null,
            "city":null,
            "locality":{
              "id":14109,
              "name":"",
              "city_id":1920,
              "code":"751310000",
              "created_at":"2021-09-30T02:01:34.000000Z",
              "updated_at":"2021-09-30T02:01:34.000000Z"
            }
          },
          "education":{
            "region":null,
            "city":null,
            "locality":{
              "id":14109,
              "name":"",
              "city_id":1920,
              "code":"751310000",
              "created_at":"",
              "updated_at":""
            },
            "school":{
              "id":0,
              "name":""
            },
            "class":5
          },
          "parent":{
            "fullname":"",
            "phone":"77013099290"
          },
          "quiz":{
            "result":48,
            "max_result":87,
            "started":"2021-09-30 18:06:28",
            "ended":"2021-09-30 18:46:21",
            "duration":2393,
            "second_step_available":0
          }
        },
      ],
      localitiesPreschoolers:[],
      citiesPreschoolers:[],
      kindergatenPreschoolers:[],
      localitiesUsers:[],
      citiesUsers:[],
      schoolUsers:[],
      localitiesSecondStepFilt:[],
      citiesSecondStepFilt:[],
      schoolSecondStepFilt:[]
    }
  },

  mounted() {
    if (localStorage.getItem('role') == '' || localStorage.getItem('role') == null) {
      this.$router.push('/authorization-manager')
    }
    else if (localStorage.getItem('role') != 'center-coach') {
      this.$router.push('/')
    }
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getCitiesPreschoolers(this.user.region.id)
    this.getCitiesUsers(this.user.region.id)
    this.getCitiesSecondStep(this.user.region.id)
    this.$http.get(window.API_ROOT + '/api/center-manager/preschoolers')
        .then((res) => {
          this.preschoolers = res.body.data
          this.preschoolersMeta=res.body.meta
          this.preschoolersLoad=false
        })
    this.$http.get(window.API_ROOT + '/api/center-manager/users')
        .then((res) => {
          this.users=res.body.data
          this.usersMeta=res.body.meta
          this.usersLoad=false
        })
    this.$http.get(window.API_ROOT + '/api/center-manager/second-step')
        .then((res) => {
          this.secondStep=res.body.data
          this.secondStepMeta=res.body.meta
          this.secondStepLoad=false
        })
    this.$http.get(window.API_ROOT + '/api/center-manager/part-one')
        .then((res) => {
          this.partOne = res.body
        })
    this.$http.get(window.API_ROOT + '/api/center-manager/part-two')
        .then((res) => {
          this.partTwo = res.body
        })
    this.$http.get(window.API_ROOT + '/api/center-manager/part-three')
        .then((res) => {
          this.partThree = res.body
        })
    this.$http.get(window.API_ROOT + '/api/center-manager/part-four')
        .then((res) => {
          this.partFour = res.body
        })
    this.$http.get(window.API_ROOT + '/api/references/regions/')
        .then((res) => {
            this.regions = res.body.data;
        })
  },
  filters: {
    Phone:function (phone){
      if(phone!=null) {
        phone = '+' + phone
        var lenPhone = phone.length;
        var tt = phone.split('');
        if (lenPhone == 12) {
          tt.splice(2, "", "(");
          tt.splice(6, "", ")");
          tt.splice(10, "", "-");
          tt.splice(13, "", "-");
        } else if (lenPhone == 13) {
          tt.splice(3, "", "(");
          tt.splice(7, "", ")");
          tt.splice(11, "", "-");
          tt.splice(14, "", "-");
        }
        return tt.join('')
      }
      else {
        return
      }
    },
    Time: function (sec) {
      var hours = Math.floor(sec/3600);
      (hours >= 1) ? sec = sec - (hours*3600) : hours = '00';
      var min = Math.floor(sec/60);
      (min >= 1) ? sec = sec - (min*60) : min = '00';
      (sec < 1) ? sec='00' : void 0;

      (min.toString().length == 1) ? min = '0'+min : void 0;
      (sec.toString().length == 1) ? sec = '0'+sec : void 0;

      return hours+':'+min+':'+sec;
    }
  },

  methods: {
    clearTable(table){
      if(table=='preschoolers'){
        this.preschoolersLoad=true
        this.$http.get(window.API_ROOT + '/api/center-manager/preschoolers')
            .then((res) => {
              this.localitiesPreschoolers=[]
              this.citiesPreschoolers=[]
              this.kindergatenPreschoolers=[]
              this.preschoolersFilt.age=''
              this.preschoolersFilt.region_id=''
              this.preschoolersFilt.city_id=''
              this.preschoolersFilt.locality_id=''
              this.preschoolersFilt.kindergarten=''
              this.preschoolersFilt.yearFrom=''
              this.preschoolersFilt.yearTo=''
              this.preschoolers = res.body.data
              this.preschoolersMeta=res.body.meta
              this.preschoolersLoad=false
            })
      }
      else if(table=='users'){
        this.usersLoad=true
        this.$http.get(window.API_ROOT + '/api/center-manager/users')
            .then((res) => {
              this.localitiesUsers=''
              this.citiesUsers=''
              this.schoolUsers=''
              this.usersFilt.age=''
              this.usersFilt.region_id=''
              this.usersFilt.city_id=''
              this.usersFilt.locality_id=''
              this.usersFilt.school=''
              this.usersFilt.yearFrom=''
              this.usersFilt.yearTo=''
              this.users=res.body.data
              this.usersMeta=res.body.meta
              this.usersLoad=false
            })
      }
      else if(table=='second-step'){
        this.secondStepLoad=true
        this.$http.get(window.API_ROOT + '/api/center-manager/second-step')
            .then((res) => {
              this.secondStepLoad=false
              this.localitiesSecondStepFilt=''
              this.citiesSecondStepFilt=''
              this.schoolSecondStepFilt=''
              this.citiesSecondStepFilt.age=''
              this.citiesSecondStepFilt.region_id=''
              this.citiesSecondStepFilt.city_id=''
              this.citiesSecondStepFilt.locality_id=''
              this.citiesSecondStepFilt.school=''
              this.citiesSecondStepFilt.yearFrom=''
              this.citiesSecondStepFilt.yearTo=''
              this.secondStep=res.body.data
              this.secondStepMeta=res.body.meta
            })
      }
    },
    secStepFilter() {
      if(this.secondStepFilt.yearFrom==null){
        this.secondStepFilt.yearFrom=''
      }
      if(this.secondStepFilt.yearTo==null){
        this.secondStepFilt.yearTo=''
      }
      this.secondStepLoad=true
      this.$http.get(window.API_ROOT + `/api/center-manager/second-step?${this.secondStepFilt.age!=false? this.secondStepFilt.age : 'filter[age][from]=&filter[age][to]=' }&filter[region_id]=${this.secondStepFilt.region_id}&filter[city_id]=${this.secondStepFilt.city_id}&filter[locality_id]=${this.secondStepFilt.locality_id}&filter[school_id]=${this.secondStepFilt.school}&filter[years][from]=${this.secondStepFilt.yearFrom}&filter[years][to]=${this.secondStepFilt.yearTo}`)
          .then((res) => {
            this.secondStep = res.body.data
            this.secondStepMeta=res.body.meta
            this.secondStepLoad=false
          })
    },
    getSchoolSecondStep(id){
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
          .then((res) => {
            this.secondStepFilt.school=''
            this.schoolSecondStepFilt = res.body.data;
            this.secStepFilter()
          })
    },
    getCitiesSecondStep(id){
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.secondStepFilt.city_id=''
            this.secondStepFilt.locality_id=''
            this.secondStepFilt.school=''
            this.localitiesSecondStepFilt=''
            this.schoolSecondStepFilt=''
            this.citiesSecondStepFilt = res.body.data;
            this.secStepFilter()
          })},
    getLocalitiesSecondStep(id){
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.secondStepFilt.locality_id=''
            this.secondStepFilt.school=''
            this.schoolSecondStepFilt=''
            this.localitiesSecondStepFilt = res.body.data;
            this.secStepFilter()
          })
    },
    getSchoolUsers(id){
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
          .then((res) => {
            this.usersFilt.school=''
            this.schoolUsers = res.body.data;
            this.usersFilter()
          })
    },
    getCitiesUsers(id){
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.usersFilt.city_id=''
            this.usersFilt.locality_id=''
            this.usersFilt.school=''
            this.localitiesUsers=''
            this.schoolUserss =''
            this.citiesUsers = res.body.data;
            this.usersFilter()
          })},
    getLocalitiesUsers(id){
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.usersFilt.locality_id=''
            this.usersFilt.school=''
            this.schoolUsers=''
            this.localitiesUsers = res.body.data;
            this.usersFilter()
          })
    },

    getKindergartenPreschoolers(id){
      this.preschoolersFilter()
      this.$http.get(window.API_ROOT + '/api/references/kindergartens/' + id)
          .then((res) => {
            this.preschoolersFilt.kindergarten=''
            this.kindergatenPreschoolers = res.body.data;
          })
    },
    getCitiesPreschoolers(id){
      this.preschoolersFilter()
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.preschoolersFilt.city_id=''
            this.preschoolersFilt.locality_id=''
            this.preschoolersFilt.kindergarten=''
            this.localitiesPreschoolers=''
            this.kindergatenPreschoolers =''
                  this.citiesPreschoolers = res.body.data;
    })},
    getLocalitiesPreschoolers(id){
      this.preschoolersFilter()
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.preschoolersFilt.locality_id=''
            this.preschoolersFilt.kindergarten=''
            this.kindergatenPreschoolers =''
            this.localitiesPreschoolers = res.body.data;
          })},


    paginateSecondStep(page){
      this.secondStepLoad=true
      this.$http.get(window.API_ROOT + '/api/center-manager/second-step?page='+page)
          .then((res) => {
            this.secondStep=res.body.data
            this.secondStepMeta=res.body.meta
            this.secondStepLoad=false
          })
    },
    paginateUsers(page){
      this.usersLoad=true
      this.$http.get(window.API_ROOT + '/api/center-manager/users?page='+page)
          .then((res) => {
            this.users=res.body.data
            this.usersMeta=res.body.meta
            this.usersLoad=false
          })
    },
    paginatePreschoolers(page){
      this.preschoolersLoad=true
      this.$http.get(window.API_ROOT + '/api/center-manager/preschoolers?page='+page)
          .then((res) => {
            this.preschoolers = res.body.data
            this.preschoolersMeta=res.body.meta
            this.preschoolersLoad=false
          })
    },
    usersFilter(){
      if(this.usersFilt.yearFrom==null){
        this.usersFilt.yearFrom=''
      }
      if(this.usersFilt.yearTo==null){
        this.usersFilt.yearTo=''
      }
      this.usersLoad=true
      this.$http.get(window.API_ROOT + `/api/center-manager/users?${this.usersFilt.age!=false? this.usersFilt.age : 'filter[age][from]=&filter[age][to]=' }&filter[region_id]=${this.usersFilt.region_id}&filter[city_id]=${this.usersFilt.city_id}&filter[locality_id]=${this.usersFilt.locality_id}&filter[school_id]=${this.usersFilt.school}&filter[years][from]=${this.usersFilt.yearFrom}&filter[years][to]=${this.usersFilt.yearTo}`)
          .then((res) => {
            this.users=res.body.data
            this.usersMeta=res.body.meta
            this.usersLoad=false
          })
    },
    preschoolersFilter() {
      if(this.preschoolersFilt.yearFrom==null){
        this.preschoolersFilt.yearFrom=''
      }
      if(this.preschoolersFilt.yearTo==null){
        this.preschoolersFilt.yearTo=''
      }
      this.preschoolersLoad=true
      this.$http.get(window.API_ROOT + `/api/center-manager/preschoolers?${this.preschoolersFilt.age!=false? this.preschoolersFilt.age : 'filter[age][from]=&filter[age][to]=' }&filter[region_id]=${this.preschoolersFilt.region_id}&filter[city_id]=${this.preschoolersFilt.city_id}&filter[locality_id]=${this.preschoolersFilt.locality_id}&filter[kindergarten]=${this.preschoolersFilt.kindergarten}&filter[years][from]=${this.preschoolersFilt.yearFrom}&filter[years][to]=${this.preschoolersFilt.yearTo}`)
          .then((res) => {
            this.preschoolers = res.body.data
            this.preschoolersMeta=res.body.meta
            this.preschoolersLoad=false
          })
    }
  },
}
</script>
<style >
.loader {
  margin: 5rem auto auto;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.pagination-lk-wrapper{
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.table-filter-8 {
  width: 11.1% !important;
  margin: 7.5px;
}

.table-filter-4 {
  width: 23.6%;
  margin: 7.5px;
}
.btn-filter-clear{
  border-radius: 5px;
  border: none;
  height: 40px;
  line-height: 40px;
  background-color: #04C2F4;
  color: white;}
.btn-filter-clear:hover{
  color: white;
}
.manager-card-label-gray-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #A0AEC0;
}

.logo-card-count {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  color: #343F68;
}

.logo-card-label {
  text-align: center;
  margin-top: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 2px;
  color: #A0AEC0;
}

.manager-card-medium-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #343F68;

  margin: auto;
}

.manager-card-medium-count {
  margin: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */

  text-align: center;

  color: #04C2F4;
}

.image-icon-wrapper {
  display: flex;
  margin: 0 auto;
  width: 62px;
  height: 62px;
  background: #04C2F4;
  border-radius: 12px;
}

.manager-card-count-blue-border-bottom {
  padding-bottom: 11px;
  border-bottom: 1px solid #E0E1E2;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #04C2F4;;

}

.manager-card-count-black-border-bottom {
  padding-bottom: 11px;
  border-bottom: 1px solid #E0E1E2;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #343F68;

}

.manager-card-label-gray {
  margin-top: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #A0AEC0;

}

.manager-card-count {
  margin-top: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #04C2F4;
}

.manager-card-label {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #343F68;
}

.table-section {
  margin-top: 100px;
}

.table-title {
  margin-bottom: 36px;
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  color: #04C2F4;

}

.manager-card-half {
  padding: 22px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 48%;
  height: 162px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  border-radius: 5px;
}

.qabilet-round {
  display: flex;
  z-index: 99;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 206px;
  height: 206px;
  border-radius: 200px;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
}

.count-logo-card-right {
  position: relative;
  margin: 8px 0;
  width: 45%;
  height: 73px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  border-radius: 5px;
}

.count-logo-card-left {
  position: relative;
  margin: 8px 0;
  width: 45%;
  height: 73px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  border-radius: 5px;
}

.count-logo-card-globe-left {
  position: absolute;
  top: 50%;
  left: 0px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  background: #04C2F4;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: 45px;
  height: 45px;
}

.count-logo-card-globe-right {
  position: absolute;
  top: 50%;
  right: 40%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  background: #04C2F4;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: 45px;
  height: 45px;
}

.manager-card-medium {
  padding: 22px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 32%;
  height: 257px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  border-radius: 5px;
}

.manager-card-mini {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 19%;
  height: 162px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  border-radius: 5px;
}

.manager-card {
  display: flex;
  flex-direction: column;
  padding: 22px 37px;
  margin-top: 50px;
  width: 31%;
  height: 162px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  border-radius: 5px;

}

.manager-cards-wrapper {
  position: relative;
  margin-top: 50px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.title-label {
  margin-top: 95px;
}

.title-label-name {
  font-size: 55px;
  color: gray;
}

.title-label-optional {
  font-size: 29.5px;
  color: gray;
}

.customScroll {
  overflow: auto
}

.customScroll::-webkit-scrollbar {
  height: 8px;
  background-color: rgba(4, 194, 244, 0.14);
  border-radius: 20px;
}

.customScroll::-webkit-scrollbar-button {

}

.customScroll::-webkit-scrollbar-track {
}

.customScroll::-webkit-scrollbar-track-piece {
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 20px;

  background: #04C2F4;

}

.customScroll::-webkit-scrollbar-corner {
}

.customScroll::-webkit-resizer {
}

.stat-age__table thead th {
  text-transform: capitalize;
  min-width: 198px;
}
.stat-age__table tbody tr:nth-child(2n){
  background: #F5F8FA;
}
</style>